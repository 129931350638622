// Modal as a separate component
import { useEffect, useRef, useState } from 'react';
import checkHtmlDialogElement from '../../utils/checkHtmlDialogElement';
import { useTheme } from '../../context/ThemeContext';

import './modal.css';
export default function Modal({
  openModal,
  // closeModal,
  children,
}) {
  const ref = useRef();
  const [isSupported, setIsSupported] = useState(false);
  const { darkTheme } = useTheme();

  useEffect(() => {
    // if (checkHtmlDialogElement()) {
    //   /** yep */
    //   // console.log('supported');
    //   setIsSupported(true);
    // } else {
    //   /** nope */
    //   // console.log('not supported');
    //   setIsSupported(false);
    // }
    setIsSupported(checkHtmlDialogElement());

    if (openModal) {
      ref.current?.showModal();
      // console.log('open modal');
    } else {
      ref.current?.close();
      // console.log('close modal');
    }
  }, [openModal]);
  return (
    <>
      {isSupported && (
        <dialog
          ref={ref}
          // onCancel={closeModal}
          className={darkTheme ? 'dialog darkTheme blackBg' : 'dialog'}
        >
          {children}
        </dialog>
      )}
      {/* <button onClick={closeModal}>Close</button> */}
    </>
  );
}
